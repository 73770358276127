import firebase from "firebase";

export async function getProductCollections() {
  const productCollections = [];

  const docs = (await firebase.firestore().collection("tables").get()).docs;
  docs.forEach((doc) => {
    const collectionName = doc.get("name");

    if (collectionName) {
      productCollections.push(collectionName);
    }
  });

  return productCollections;
}

/**
 * Get an order by a specific key and value pair
 * @param key
 * @param value
 * @param productCollections
 * @return {Promise<{}>}
 */
export async function getOrder(key, value, productCollections) {
  const orderQueries = await Promise.all(
    Object.entries(productCollections).map(
      async ([productName, productData]) => {
        const collectionName = productData.orders.collection;
        const doc = (
          await this.firestore
            .collection(collectionName)
            .where(key, "==", value)
            .limit(1)
            .get()
        ).docs[0];

        if (typeof doc === "undefined") {
          return Error(`No order in collection '${collectionName}' found!`);
        }
        console.log(
          `Found order by ${key} == ${value} in product: ${productName}`
        );

        return {
          ...doc.data(),
          id: doc.id,
          product: productName,
        };
      }
    )
  );

  const orders = orderQueries.filter((promise) => !(promise instanceof Error));

  if (orders.length === 0) {
    throw new Error(`Could not find order by ${key}: ${value}`);
  }

  return orders[0];
}

/**
 * Retrieve an order by its orderId
 * @param orderId
 * @return {Promise<{}>}
 */
export function getOrderByOrderId(orderId) {
  console.debug("Get order by orderId:", orderId);
  return getOrder("orderid", orderId);
}

export function getCollectionRef(collectionName) {
  return firebase.firestore().collection(collectionName);
}

export async function addTrackingInfoToFirebase(collectionRef, row) {
  let message;

  await collectionRef
    .where("orderid", "==", row["orderid"])
    .limit(1)
    .get()
    .then((orders) => {
      orders.forEach((order) => {
        if (!order.get("tracking_no") || !order.get("wc_id")) {
          message = `Add tracking info: ${row["tracking_no"]} to orderId ${row["orderid"]}`;

          collectionRef.doc(order.id).update({
            tracking_no: row["tracking_no"],
            wc_id: row["wc_id"],
          });

          return;
        }

        message = `Skipped tracking info: ${row["tracking_no"]} to orderId ${row["orderid"]}`;
      });
    })
    .catch((error) => {
      message = `Error getting documents: ${error}`;
    });

  return message;
}

export async function getOrdersWithTrackingNo(collectionRef) {
  return collectionRef.where("tracking_no", "!=", "").get();
}
