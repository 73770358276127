<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Produkte</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Sync' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Sync" />
              <span class="nav-text">Sync</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Batches' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Batches</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="breadcrumb">
        <h1>Produkte</h1>
      </div>

      <div class="separator-breadcrumb border-top"></div>
      <div class="row">
        <div
          :key="table.key"
          class="col-lg-3 col-md-6 col-sm-6"
          v-for="table of tables"
        >
          <div
            v-if="!!table.display"
            class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
          >
            <div class="card-body">
              <!-- Icon -->
              <span :class="table.class" style="font-size: 67px; color: #999" />
              <!-- Title -->
              <div class="content">
                <p class="text-muted mt-2 mb-2 text-left font-weight-bold">
                  {{ table.title || table.name }}
                </p>
                <!-- Buttons -->
                <p class="lead text-primary text-24 mb-2">
                  <span
                    @click="editTable1(table.key)"
                    class="text-success mr-2"
                    style="cursor: pointer"
                    title="Produkt bearbeiten"
                  >
                    <i
                      aria-hidden="true"
                      class="nav-icon i-Pen-2 font-weight-bold"
                    />
                  </span>

                  <router-link
                    :to="{ name: 'ManageTable', params: { id: table.key } }"
                    title="Bestellungen anzeigen"
                  >
                    <span class="text-default mr-2">
                      <i
                        aria-hidden="true"
                        class="nav-icon i-Eye font-weight-bold"
                      />
                    </span>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'CancellationRequests',
                      params: { id: table.name },
                    }"
                    title="Stornierungsanfragen anzeigen"
                  >
                    <span class="text-danger mr-2">
                      <i
                        aria-hidden="true"
                        class="nav-icon i-Full-Cart font-weight-bold"
                      />
                    </span>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      aria-hidden="true"
      aria-labelledby="exampleModalLabel"
      class="modal fade"
      id="exampleModal"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Neue Tabelle anlegen
            </h5>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form id="fTable">
            <div class="modal-body">
              <div class="alert alert-danger" v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li :key="error" v-for="error in errors">{{ error }}</li>
                </ul>
              </div>

              <div class="row">
                <div class="col-md-12 form-group mb-3">
                  <label for="producttitle">Product Title*</label>
                  <input
                    class="form-control form-control-rounded"
                    id="producttitle"
                    placeholder="Enter Product Title"
                    required
                    type="text"
                    v-model="newTable.title"
                  />
                </div>
                <div class="col-md-12 form-group mb-3">
                  <label for="tblname">Table Name*</label>
                  <input
                    class="form-control form-control-rounded"
                    id="tblname"
                    placeholder="Enter Table Name"
                    required
                    type="text"
                    v-model="newTable.name"
                  />
                </div>
                <div class="col-md-12 form-group mb-3">
                  <label for="icnname">
                    <a
                      href="https://fontawesome.com/v4.7.0/icons/"
                      target="_new"
                      ><u>Icon Class</u></a
                    >*
                  </label>
                  <input
                    class="form-control form-control-rounded"
                    id="icnname"
                    placeholder="fa fa-user-o"
                    required
                    type="text"
                    v-model="newTable.class"
                  />
                </div>
              </div>
              <div
                :key="index"
                class="row"
                v-for="(newTable, index) in newTables"
              >
                <div class="col-md-6 form-group mb-3">
                  <label for="fieldname">Field Name*</label>
                  <input
                    class="form-control form-control-rounded"
                    placeholder="Enter Field Name"
                    required
                    type="text"
                    v-model="newTable.field"
                  />
                </div>
                <div class="col-md-4 form-group mb-3">
                  <label for="type">Type*</label>
                  <select
                    class="form-control form-control-rounded"
                    v-model="newTable.dataType"
                  >
                    <option value="String">String</option>
                    <option value="Number">Number</option>
                  </select>
                </div>
                <div class="col-md-1 form-group mb-3" v-if="index > 0">
                  <label>&nbsp;</label>
                  <button
                    @click="deleteRow(index)"
                    class="btn btn-danger"
                    type="button"
                  >
                    X
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-1 form-group mb-3 pull-right">
                  <button
                    @click="addRow()"
                    class="btn btn-primary"
                    type="button"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                class="btn btn-secondary"
                data-dismiss="modal"
                ref="closeModal"
                type="button"
              >
                Close
              </button>
              <button @click="addTable" class="btn btn-primary" type="button">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      aria-hidden="true"
      aria-labelledby=""
      class="modal fade"
      id="editModal"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="">Update Table</h5>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="alert alert-danger" v-if="errors.length">
              <b>Please correct the following error(s):</b>
              <ul>
                <li :key="error" v-for="error in errors">{{ error }}</li>
              </ul>
            </div>

            <div class="row">
              <div class="col-md-12 form-group mb-3">
                <label>Product Title</label>
                <input
                  class="form-control form-control-rounded"
                  placeholder="Enter Product Title"
                  type="text"
                  v-model="editTableValues.title"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group mb-3">
                <label>Table Name</label>
                <input
                  class="form-control form-control-rounded"
                  placeholder="Enter Table Name"
                  type="text"
                  v-model="editTableValues.name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group mb-3">
                <label
                  ><a href="https://fontawesome.com/v4.7.0/icons/"
                    ><u>Icon Class</u></a
                  ></label
                >
                <input
                  class="form-control form-control-rounded"
                  placeholder="fa fa-user-o"
                  type="text"
                  v-model="editTableValues.class"
                />
              </div>
            </div>

            <div
              :key="index"
              class="row"
              v-for="(field, index) in editTables.field"
            >
              <div class="col-md-6 form-group mb-3">
                <label for="fieldname">Field Name</label>
                <input
                  :name="'field' + index"
                  class="form-control form-control-rounded"
                  placeholder="Enter Field Name"
                  type="text"
                  v-model="editTables.field[index]"
                />
              </div>
              <div class="col-md-4 form-group mb-3">
                <label for="type">Type</label>
                <select
                  :name="'dataType' + index"
                  class="form-control form-control-rounded"
                  v-model="editTables.dataType[index]"
                >
                  <option value="String">String</option>

                  <option value="Number">Number</option>
                </select>
              </div>
              <!--<div class="col-md-1 form-group mb-3" v-if="index > 0">
                <label>&nbsp;</label>
                <button type="button" class="btn btn-danger" @click="deleteEditRow(index)">X</button>
              </div>-->
            </div>
            <div class="row">
              <div class="col-md-1 form-group mb-3 pull-right">
                <button
                  @click="addEditRow()"
                  class="btn btn-primary"
                  type="button"
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              ref="closeEditModal"
              type="button"
            >
              Close
            </button>
            <button @click="updateTable" class="btn btn-primary" type="button">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";

export default {
  name: "tables",

  data() {
    return {
      tables: [],
      ref: firebase.firestore().collection("tables"),

      photoURL: "",
      errors: [],
      newTable: {
        name: "",
        class: "",
        field: "",
        dataType: "",
      },
      editTableValues: {
        name: "",
        class: "",
        field: "",
        dataType: "",
      },
      tableData: {},
      editField: [],
      editDataType: [],
      tableEditKey: "",
      newTables: [],
      editTables: [],
      fieldStr: "",
      dataTypeStr: "",
    };
  },

  created: function () {
    this.ref.onSnapshot((querySnapshot) => {
      const tables = [];

      querySnapshot.forEach((doc) => {
        tables.push({
          key: doc.id,
          class: doc.data().class,
          dataType: doc.data().dataType,
          field: doc.data().field,
          name: doc.data().name,
          title: doc.data().title,
          display: doc.data().display === undefined ? true : doc.data().display,
        });
      });

      tables.reverse();

      this.tables = tables;
    });

    this.addRow();
    this.userData();
  },

  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },

    userData: function () {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.photoURL = user.photoURL;
      }
    },

    addTable: function () {
      let error = false;
      this.errors = [];

      this.fieldStr = Array.prototype.map
        .call(this.newTables, (s) => s.field)
        .toString();
      this.dataTypeStr = Array.prototype.map
        .call(this.newTables, (s) => s.dataType)
        .toString();

      if (!this.newTable.title) {
        this.errors.push("Product title is required.");
        error = true;
      }
      if (!this.newTable.name) {
        this.errors.push("Table name is required.");
        error = true;
      }
      if (!this.newTable.class) {
        this.errors.push("Icon name is required.");
        error = true;
      }

      this.newTables.map((s) => {
        if (!s.field) {
          this.errors.push("Field name is required.");
          error = true;
        }
      });

      this.newTables.map((s) => {
        if (!s.dataType) {
          this.errors.push("Field type is required.");
          error = true;
        }
      });

      if (error == false) {
        firebase.firestore().collection("tables").add({
          title: this.newTable.title,
          name: this.newTable.name,
          class: this.newTable.class,
          field: this.fieldStr,
          dataType: this.dataTypeStr,
        });

        swal({
          type: "success",
          title: "Success!",
          text: "Your table has been saved",
          showConfirmButton: false,
          timer: 1500,
        });

        this.newTable.title = "";
        this.newTable.name = "";
        this.newTable.class = "";
        this.newTables.field = "";
        this.newTable.field = "";
        this.newTables.dataType = "";
        this.newTable.dataType = "";

        const elem = this.$refs.closeModal;
        elem.click();
      }
    },

    updateTable: function () {
      let error = false;
      this.errors = [];

      this.fieldStr = this.editTables.field.join();
      this.dataTypeStr = this.editTables.dataType.join();

      if (!this.editTableValues.title) {
        this.errors.push("Product title is required.");
        error = true;
      }
      if (!this.editTableValues.name) {
        this.errors.push("Table name is required.");
        error = true;
      }
      if (!this.editTableValues.class) {
        this.errors.push("Icon name is required.");
        error = true;
      }

      const fieldArr = this.editTables.field;
      const dataTypeArr = this.editTables.dataType;

      fieldArr.map((s) => {
        if (!s) {
          this.errors.push("Field name is required.");
          error = true;
        }
      });

      dataTypeArr.map((s) => {
        if (!s) {
          this.errors.push("Field type is required.");
          error = true;
        }
      });

      if (error == false) {
        firebase
          .firestore()
          .collection("tables")
          .doc(this.tableEditKey)
          .update({
            title: this.editTableValues.title,
            name: this.editTableValues.name,
            class: this.editTableValues.class,
            field: this.fieldStr,
            dataType: this.dataTypeStr,
          });

        this.editTableValues.title = "";
        this.editTableValues.name = "";
        this.editTableValues.class = "";
        this.editTables.field = "";
        this.editTables.dataType = "";
        this.tableEditKey = "";

        swal({
          type: "success",
          title: "Success!",
          text: "Your table has been updated",
          showConfirmButton: false,
          timer: 1500,
        });

        const elem = this.$refs.closeEditModal;
        elem.click();
      }
    },

    editTable1(key) {
      const docRef = firebase.firestore().collection("tables").doc(key);
      let title, name, classname, field, dataType;
      const self = this;

      $.when(
        docRef.get().then(function (doc) {
          if (doc.exists) {
            title = doc.data().title;
            name = doc.data().name;
            classname = doc.data().class;
            field = doc.data().field;
            dataType = doc.data().dataType;
          }
        })
      ).then(function () {
        self.editTableValues.title = title;
        self.editTableValues.name = name;

        self.editTableValues.class = classname;
        self.editTables.field = field.split(",");
        self.editTables.dataType = dataType.split(",");

        $("#editModal").modal("show");
      });

      this.tableEditKey = key;
    },

    addRow() {
      this.newTables.push({
        field: "",
        dataType: "",
      });
    },

    addEditRow() {
      this.editTables.field.push("");
      this.editTables.dataType.push("");

      this.fieldStr = this.editTables.field.join();
      this.dataTypeStr = this.editTables.dataType.join();

      firebase.firestore().collection("tables").doc(this.tableEditKey).update({
        title: this.editTableValues.title,
        name: this.editTableValues.name,
        class: this.editTableValues.class,
        field: this.fieldStr,
        dataType: this.dataTypeStr,
      });
    },

    deleteEditRow(index) {
      this.editTables.field.splice(index, 1);
      this.editTables.dataType.splice(index, 1);

      this.fieldStr = this.editTables.field.join();
      this.dataTypeStr = this.editTables.dataType.join();

      firebase.firestore().collection("tables").doc(this.tableEditKey).update({
        title: this.editTableValues.title,
        name: this.editTableValues.name,
        class: this.editTableValues.class,
        field: this.fieldStr,
        dataType: this.dataTypeStr,
      });
    },

    deleteRow(index) {
      this.newTables.splice(index, 1);
    },
  },
};
</script>
