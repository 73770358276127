<template>
  <div
    class="auth-layout-wrap"
    style="background-image: url(/assets/images/photo-wide-4.jpg)"
  >
    <div class="auth-content">
      <div class="card o-hidden">
        <div class="row">
          <div class="col-md-6">
            <div class="p-4">
              <div class="auth-logo text-center mb-4">
                <img alt="" src="/assets/images/logo.png" />
              </div>
              <h1 class="mb-3 text-18">Sign Up</h1>
              <form>
                <div class="form-group">
                  <label for="email">Email address</label>
                  <input
                    class="form-control form-control-rounded"
                    id="email"
                    type="text"
                    v-model="email"
                  />
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input
                    class="form-control form-control-rounded"
                    id="password"
                    type="password"
                    v-model="password"
                  />
                </div>
                <button
                  @click="signUp"
                  class="btn btn-rounded btn-primary btn-block mt-2"
                  type="button"
                >
                  Sign Up
                </button>
              </form>
            </div>
          </div>
          <div
            class="col-md-6 text-center"
            style="
              background-size: cover;
              background-image: url(/assets/images/photo-long-3.jpg);
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "signUp",

  data() {
    return {
      email: "",
      password: "",
    };
  },

  methods: {
    signUp: function () {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(
          (user) => {
            this.$router.replace("home");
          },
          (err) => {
            alert("Oops. " + err.message);
          }
        );
    },
  },
};
</script>
