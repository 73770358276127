import firebase from "firebase";
import VueFire from "vuefire";
import Vue from "vue";
import Router from "vue-router";

import Dashboard from "@/components/Dashboard";
import Search from "@/components/Search";
import Login from "@/components/Login";
import SignUp from "@/components/SignUp";
import Products from "@/components/Products";
import Files from "@/components/Files";
import ManageTable from "@/components/ManageTable";
import UpdateProfile from "@/components/UpdateProfile";
import TableDetails from "@/components/TableDetails";
import Sync from "@/components/Sync";
import CancellationRequests from "@/components/CancellationRequests";
import Batches from "@/components/Batches";

Vue.use(VueFire);
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "*",
      redirect: "/login",
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/sign-up",
      name: "SignUp",
      component: SignUp,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/search",
      name: "Search",
      component: Search,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
    {
      path: "/products",
      name: "Products",
      component: Products,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sync",
      name: "Sync",
      component: Sync,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/batches",
      name: "Batches",
      component: Batches,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/files",
      name: "Files",
      component: Files,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/manageTable/:id",
      name: "ManageTable",
      component: ManageTable,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/cancellationRequests/:id",
      name: "CancellationRequests",
      component: CancellationRequests,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/updateProfile",
      name: "UpdateProfile",
      component: UpdateProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/details/:table/:document",
      name: "TableDetails",
      component: TableDetails,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next("login");
  else if (!requiresAuth && currentUser) next("dashboard");
  else next();
});

export default router;
