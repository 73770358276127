<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              id="userDropdown"
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a class="dropdown-item" href="#" @click="logout">Sign out</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item active">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart"></i>
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Produkte</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Sync' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Sync" />
              <span class="nav-text">Sync</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Batches' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Batches</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <!--=============== Left side End ================-->

    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="breadcrumb">
        <h1>Dashboard</h1>
      </div>
      <div class="separator-breadcrumb border-top"></div>

      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">
                    Carlender 2024
                    <span class="badge badge-primary">Aktiv</span>
                  </h5>
                  <p class="mt-0">Bestellungen (2023)</p>

                  <router-link
                      :to="{
                      name: 'ManageTable',
                      params: { id: '2023_4-carlender' },
                    }"
                      class="btn btn-primary mb-1"
                  >
                    Verwalten
                  </router-link>

                  <br />

                  <router-link
                      :to="{
                      name: 'CancellationRequests',
                      params: { id: '2023_4-carlender-orders' },
                    }"
                      class="btn btn-danger"
                  >
                    Stornierungsanfragen
                  </router-link>
                  <div class="card-socials-simple mt-4"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">
                    Biografie Hörbuch
                    <span class="badge badge-primary">Aktiv</span>
                  </h5>
                  <p class="mt-0">Bestellungen (Durchgehend)</p>

                  <router-link
                    :to="{
                      name: 'ManageTable',
                      params: { id: '2021_1-audiobook' },
                    }"
                    class="btn btn-primary mb-1"
                  >
                    Verwalten
                  </router-link>

                  <br />

                  <router-link
                    :to="{
                      name: 'CancellationRequests',
                      params: { id: '2021_1-audiobook-orders' },
                    }"
                    class="btn btn-danger"
                  >
                    Stornierungsanfragen
                  </router-link>
                  <div class="card-socials-simple mt-4"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">
                    PACE Museum <span class="badge badge-primary">Aktiv</span>
                  </h5>
                  <p class="mt-0">Bestellungen (Durchgehend)</p>

                  <router-link
                    :to="{
                      name: 'ManageTable',
                      params: { id: 'pace-ticket-orders' },
                    }"
                    class="btn btn-primary mb-1"
                  >
                    Verwalten
                  </router-link>

                  <br />

                  <router-link
                    :to="{
                      name: 'CancellationRequests',
                      params: { id: 'pace-ticket-orders' },
                    }"
                    class="btn btn-danger"
                  >
                    Stornierungsanfragen
                  </router-link>
                  <div class="card-socials-simple mt-4"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">
                    Carlender 2023
                    <span class="badge badge-secondary">Inaktiv</span>
                  </h5>
                  <p class="mt-0">Bestellungen (2022)</p>

                  <router-link
                    :to="{
                      name: 'ManageTable',
                      params: { id: '2022_2-carlender' },
                    }"
                    class="btn btn-primary mb-1"
                  >
                    Verwalten
                  </router-link>

                  <br />

                  <router-link
                    :to="{
                      name: 'CancellationRequests',
                      params: { id: '2022_2-carlender-orders' },
                    }"
                    class="btn btn-danger"
                  >
                    Stornierungsanfragen
                  </router-link>
                  <div class="card-socials-simple mt-4"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">
                    Boost Wars Modellauto
                    <span class="badge badge-secondary">Inaktiv</span>
                  </h5>
                  <p class="mt-0">Bestellungen (2022)</p>

                  <router-link
                    :to="{
                      name: 'ManageTable',
                      params: { id: '2022_1-boostwars' },
                    }"
                    class="btn btn-primary mb-1"
                  >
                    Verwalten
                  </router-link>

                  <br />

                  <router-link
                    :to="{
                      name: 'CancellationRequests',
                      params: { id: '2022_1-boostwars-orders' },
                    }"
                    class="btn btn-danger"
                  >
                    Stornierungsanfragen
                  </router-link>
                  <div class="card-socials-simple mt-4"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">
                    Carlender 2022
                    <span class="badge badge-secondary">Inaktiv</span>
                  </h5>
                  <p class="mt-0">Bestellungen (2021)</p>

                  <router-link
                    :to="{
                      name: 'ManageTable',
                      params: { id: '2021_2-carlender' },
                    }"
                    class="btn btn-primary mb-1"
                  >
                    Verwalten
                  </router-link>

                  <br />

                  <router-link
                    :to="{
                      name: 'CancellationRequests',
                      params: { id: '2021_2-carlender-orders' },
                    }"
                    class="btn btn-danger"
                  >
                    Stornierungsanfragen
                  </router-link>
                  <div class="card-socials-simple mt-4"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">
                    Biografie
                    <span class="badge badge-secondary">Inaktiv</span>
                  </h5>
                  <p class="mt-0">Bestellungen (2021)</p>

                  <router-link
                    :to="{
                      name: 'ManageTable',
                      params: { id: '2021_3-biografie' },
                    }"
                    class="btn btn-primary mb-1"
                  >
                    Verwalten
                  </router-link>

                  <br />

                  <router-link
                    :to="{
                      name: 'CancellationRequests',
                      params: { id: '2021_3-biografie-orders' },
                    }"
                    class="btn btn-danger"
                  >
                    Stornierungsanfragen
                  </router-link>
                  <div class="card-socials-simple mt-4"></div>
                </div>
              </div>
            </div>
          </div>

          <br style="clear: both" />

          <div class="breadcrumb">
            <h1>
              Carlender 2024 Bestellungen
              <span class="badge badge-success">Neu</span>
            </h1>
          </div>
          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Carlender 2024 Bestellungen</h5>
                  <p class="mt-0">Von PayPal verifizierte Bestellungen</p>

                  <h2>{{ orderCountCarlender2024 }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Carlender 2024 verkauft</h5>
                  <p class="mt-0">Von PayPal verifizierte Verkäufe</p>

                  <h2>{{ orderedProductsCountCarlender2024 }}</h2>
                </div>
              </div>
            </div>
          </div>

          <br style="clear: both" />

          <div class="breadcrumb">
            <h1>
              Carlender 2023 Bestellungen
              <span class="badge badge-secondary">Final</span>
            </h1>
          </div>
          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Carlender 2023 Bestellungen</h5>
                  <p class="mt-0">Von PayPal verifizierte Bestellungen</p>

                  <h2>{{ orderCountCarlender2023 }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Carlender 2023 verkauft</h5>
                  <p class="mt-0">Von PayPal verifizierte Verkäufe</p>

                  <h2>{{ orderedProductsCountCarlender2023 }}</h2>
                </div>
              </div>
            </div>
          </div>

          <br style="clear: both" />

          <div class="breadcrumb">
            <h1>
              Boost Wars Bestellungen
              <span class="badge badge-secondary">Final</span>
            </h1>
          </div>
          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Modellauto</h5>
                  <p class="mt-0">Verifizierte Bestellungen (gesamt)</p>

                  <h2>{{ orderCountBoostwars }}</h2>
                </div>
              </div>
            </div>
          </div>

          <br style="clear: both" />

          <div class="breadcrumb">
            <h1>
              Biografie Bestellungen
              <span class="badge badge-secondary">Final</span>
            </h1>
          </div>
          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Biografie Bestellungen 2021</h5>
                  <p class="mt-0">Verifizierte Bestellungen (gesamt)</p>

                  <h2>{{ orderCountBiografie }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Biografie Bestellungen 2020</h5>
                  <p class="mt-0">Verifizierte Bestellungen (gesamt)</p>

                  <h2>130.297</h2>
                </div>
              </div>
            </div>
          </div>

          <br style="clear: both" />

          <div class="breadcrumb">
            <h1>
              Carlender 2022 Bestellungen
              <span class="badge badge-secondary">Final</span>
            </h1>
          </div>
          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Carlender 2022 Bestellungen</h5>
                  <p class="mt-0">Von PayPal verifizierte Bestellungen</p>

                  <h2>{{ orderCountCarlender2022 }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Carlender 2022 verkauft</h5>
                  <p class="mt-0">Von PayPal verifizierte Verkäufe</p>

                  <h2>{{ orderedProductsCountCarlender2022 }}</h2>
                </div>
              </div>
            </div>
          </div>

          <br style="clear: both" />

          <div class="breadcrumb">
            <h1>
              PACE Tickets
              <span class="badge badge-primary">Aktiv</span>
            </h1>
          </div>
          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0"><b>Bestellungen insgesamt</b></h5>
                  <p class="mt-0">Verifizierte Bestellungen</p>

                  <h2>{{ orderCountPACE }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0"><b>Verkaufte Tickets</b></h5>
                  <p class="mt-0">Verifizierte verkaufte Tickets (gesamt)</p>

                  <h2>{{ orderedProductsCountPACE }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Tickets Erwachsene</h5>
                  <p class="mt-0">Verifizierte verkaufte Tickets</p>

                  <h2>{{ orderedProductsAdultCountPACE }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Tickets Erwachsene mit Tablet</h5>
                  <p class="mt-0">Verifizierte verkaufte Tickets</p>

                  <h2>{{ orderedProductsAdultTabletCountPACE }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Tickets Reduziert</h5>
                  <p class="mt-0">Verifizierte verkaufte Tickets</p>

                  <h2>{{ orderedProductsReducedCountPACE }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Tickets Reduziert mit Tablet</h5>
                  <p class="mt-0">Verifizierte verkaufte Tickets</p>

                  <h2>{{ orderedProductsReducedTabletCountPACE }}</h2>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Verfügbare Tickets</h5>
                  <p class="mt-0"></p>

                  <h2>{{ unusedTicketsCountPACE }}</h2>
                </div>
              </div>
            </div>
          </div>

          <br style="clear: both" />

          <div class="breadcrumb">
            <h1>
              Hörbuch Bestellungen
              <span class="badge badge-primary">Aktiv</span>
            </h1>
          </div>
          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Biografie Hörbuch Bestellungen</h5>
                  <p class="mt-0">Verifizierte Bezahlungen (LIVE)</p>

                  <h2>{{ orderCountAudiobook }}</h2>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-6">
              <div class="card text-left">
                <div class="card-body">
                  <h4 class="card-title mb-3">
                    Vorverarbeite Carlender 2024 Bestellungen nach Datum
                  </h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="display table table-striped table-bordered"
                      id="scroll_horizontal_table_ca"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Datum</th>
                          <th>Verkäufe</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-bind:key="day"
                          v-for="day in statsCarlender2024.orders_per_day"
                        >
                          <td>{{ day.created }}</td>
                          <td>{{ day.orders_created }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="separator-breadcrumb border-top"></div>

          <div class="row">
            <div class="col-md-6">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Bestellungen</h5>
                  <p class="mt-0">
                    Von amicaldo Shipping verarbeitete Bestellungen
                  </p>

                  <h2>{{ statsCarlender2024.total_orders }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Carlender 2024</h5>
                  <p class="mt-0">In amicaldo Shipping importierte Carlender</p>

                  <h2>{{ statsCarlender2024.total_item_sales }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">DHL Labels</h5>
                  <p class="mt-0">Generiert</p>

                  <h3>
                    {{ statsCarlender2024.dhl_labels }} /
                    {{
                      statsCarlender2024.total_orders -
                      statsCarlender2024.status_refunded
                    }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">DHL Labels</h5>
                  <p class="mt-0">Ausgeliefert</p>

                  <h3>
                    {{ statsCarlender2024.labels_at_shipping }} /
                    {{ statsCarlender2024.total_orders }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">Registriert</p>

                  <h2>{{ statsCarlender2024.status_is_imported }}</h2>

                  <div class="card-socials-simple mt-4">
                    Bestellung wurde in amicaldo Shipping importiert. <br />
                    Du bist in Batch XY
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">Verarbeitet</p>

                  <h2>{{ statsCarlender2024.status_processed }}</h2>

                  <div class="card-socials-simple mt-4">
                    Bestellung wurde in amicaldo Shipping für die Verarbeitung
                    vorgemerkt. Hier sind deine Addressdaten. Bitte kontrolliere
                    nochmal bevor wir dein Versandlabel generieren.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">DHL Label generiert</p>

                  <h2>{{ statsCarlender2024.status_dhl_generated }}</h2>

                  <div class="card-socials-simple mt-4">
                    Das DHL Label für diese Bestellung wurde erzeugt. Änderung
                    an Addressdaten nicht mehr möglich. Wir schicken dir deine
                    TrackingNr sobald die Bestellung für den Versand geplant
                    wurde.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">In Batch aufgenommen</p>

                  <h2>{{ statsCarlender2024.status_in_batch }}</h2>
                  <div class="card-socials-simple mt-4">
                    Deine Bestellung wurde für den Versand geplant. Hier ist
                    deine TrackingNr: XYZ Die nächste E-Mail bekommst du wenn
                    deine Bestellung an den Versand gegeben wurde
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">An Versand ausgeliefert</p>

                  <h2>{{ statsCarlender2024.status_in_shipping }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">Wurde versendet</p>

                  <h2>{{ statsCarlender2024.status_is_shipped }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">Abgeschlossen</p>

                  <h2>{{ statsCarlender2024.status_completed }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">Storniert</p>

                  <h2>{{ statsCarlender2024.status_refunded }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-profile-1 mb-4">
                <div class="card-body text-center">
                  <h5 class="m-0">Status</h5>
                  <p class="mt-0">Adresse fehlerhaft</p>

                  <h2>{{ statsCarlender2024.status_invalid_address }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";
import axios from "axios";

export default {
  name: "home",

  props: {
    pEmail: null,
    pDisplayName: null,
    pPhotoURL: null,
  },

  data() {
    return {
      email: this.pEmail,
      displayName: this.pDisplayName,
      photoURL: this.pPhotoURL,
      stats: {},
      statsCarlender2024: {},
      orderCountBoostwars: 0,
      orderedProductsCountBoostwars: 0,
      orderCountBiografie: 0,
      orderedProductsCountBiografie: 0,
      orderCountCarlender2022: 0,
      orderedProductsCountCarlender2022: 0,
      orderCountCarlender2023: 0,
      orderedProductsCountCarlender2023: 0,
      orderCountCarlender2024: 0,
      orderedProductsCountCarlender2024: 0,
      orderCountPACE: 0,
      orderedProductsCountPACE: 0,
      orderedProductsAdultTabletCountPACE: 0,
      orderedProductsAdultCountPACE: 0,
      orderedProductsReducedTabletCountPACE: 0,
      orderedProductsReducedCountPACE: 0,
      unusedTicketsCountPACE: "...",
      orderCountAudiobook: 0,
      orderedProductsCountAudiobook: 0,
      $authDocument: null,
    };
  },

  created: function () {
    this.initMetadata();
    this.userData();
    this.getStats();
    this.getWPStats();
  },

  methods: {
    initMetadata: async function () {
      this.$authDocument = firebase
        .firestore()
        .collection("werkstatt-meta")
        .doc("auth")
        .get()
        .then((doc) => ({ ...doc.data(), docId: doc.id }));
    },

    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },

    userData: function () {
      var user = firebase.auth().currentUser;
      var name, email, photoUrl;

      if (user != null) {
        name = user.displayName;
        email = user.email;
        photoUrl = user.photoURL;
      }
      if (this.email == null) {
        this.email = email;
        this.displayName = name;
        this.photoURL = photoUrl;
      }
    },

    update: function () {
      this.$router.replace("updateProfile");
    },

    getStats: function () {
      firebase
        .firestore()
        .collection("/ordermeta/2022_1-boostwars/shards")
        .onSnapshot(async (shards) => {
          let orderCounter = 0,
            orderedProductsCounter = 0;

          await shards.forEach((shard) => {
            orderCounter += shard.get("orderCount") || 0;
            orderedProductsCounter += shard.get("orderedProductsCount") || 0;
          });

          this.orderCountBoostwars = orderCounter.toLocaleString("de");
          this.orderedProductsCountBoostwars =
            orderedProductsCounter.toLocaleString("de");
        });

      firebase
        .firestore()
        .collection("/ordermeta/2021_3-biografie/shards")
        .onSnapshot(async (shards) => {
          let orderCounter = 0,
            orderedProductsCounter = 0;

          await shards.forEach((shard) => {
            orderCounter += shard.get("orderCount") || 0;
            orderedProductsCounter += shard.get("orderedProductsCount") || 0;
          });

          this.orderCountBiografie = orderCounter.toLocaleString("de");
          this.orderedProductsCountBiografie =
            orderedProductsCounter.toLocaleString("de");
        });

      firebase
        .firestore()
        .collection("/ordermeta/2021_2-carlender/shards")
        .onSnapshot(async (shards) => {
          let orderCounter = 0,
            orderedProductsCounter = 0;

          await shards.forEach((shard) => {
            orderCounter += shard.get("orderCount") || 0;
            orderedProductsCounter += shard.get("orderedProductsCount") || 0;
          });

          this.orderCountCarlender2022 = orderCounter.toLocaleString("de");
          this.orderedProductsCountCarlender2022 =
            orderedProductsCounter.toLocaleString("de");
        });

      firebase
        .firestore()
        .collection("/ordermeta/2022_2-carlender/shards")
        .onSnapshot(async (shards) => {
          let orderCounter = 0,
            orderedProductsCounter = 0;

          await shards.forEach((shard) => {
            orderCounter += shard.get("orderCount") || 0;
            orderedProductsCounter += shard.get("orderedProductsCount") || 0;
          });

          this.orderCountCarlender2023 = orderCounter.toLocaleString("de");
          this.orderedProductsCountCarlender2023 =
            orderedProductsCounter.toLocaleString("de");
        });

      firebase
          .firestore()
          .collection("/ordermeta/2023_4-carlender/shards")
          .onSnapshot(async (shards) => {
            let orderCounter = 0,
                orderedProductsCounter = 0;

            await shards.forEach((shard) => {
              orderCounter += shard.get("orderCount") || 0;
              orderedProductsCounter += shard.get("orderedProductsCount") || 0;
            });

            this.orderCountCarlender2024 = orderCounter.toLocaleString("de");
            this.orderedProductsCountCarlender2024 =
                orderedProductsCounter.toLocaleString("de");
          });

      firebase
        .firestore()
        .collection("/ordermeta/pace-ticket/shards")
        .onSnapshot(async (shards) => {
          let orderCounter = 0,
            orderedProductsAdultCounter = 0,
            orderedProductsAdultTabletCounter = 0,
            orderedProductsReducedCounter = 0,
            orderedProductsReducedTabletCounter = 0;

          await shards.forEach((shard) => {
            orderCounter += shard.get("orderCount") || 0;

            orderedProductsAdultTabletCounter +=
              shard.get("amount_adult_tabletCount") || 0;
            orderedProductsAdultCounter += shard.get("amount_adultCount") || 0;
            orderedProductsReducedTabletCounter +=
              shard.get("amount_reduced_tabletCount") || 0;
            orderedProductsReducedCounter +=
              shard.get("amount_reducedCount") || 0;
          });

          this.orderCountPACE = orderCounter.toLocaleString("de");
          this.orderedProductsCountPACE = (
            orderedProductsAdultTabletCounter +
            orderedProductsAdultCounter +
            orderedProductsReducedTabletCounter +
            orderedProductsReducedCounter
          ).toLocaleString("de");

          this.orderedProductsAdultTabletCountPACE =
            orderedProductsAdultTabletCounter.toLocaleString("de");
          this.orderedProductsAdultCountPACE =
            orderedProductsAdultCounter.toLocaleString("de");
          this.orderedProductsReducedTabletCountPACE =
            orderedProductsReducedTabletCounter.toLocaleString("de");
          this.orderedProductsReducedCountPACE =
            orderedProductsReducedCounter.toLocaleString("de");
        });

      axios
        .get(
          "https://pace-ticket-service.jp-superstore.de/tickets/unused/count"
        )
        .then(
          ({ data }) =>
            (this.unusedTicketsCountPACE = data.count.toLocaleString("de"))
        )
        .catch((err) => {
          console.log(err);

          this.unusedTicketsCountPACE = "?";
        });

      firebase
        .firestore()
        .collection("/ordermeta/2021_1-audiobook/shards")
        .onSnapshot(async (shards) => {
          let orderCounter = 0,
            orderedProductsCounter = 0;

          await shards.forEach((shard) => {
            orderCounter += shard.get("orderCount") || 0;
            orderedProductsCounter += shard.get("orderedProductsCount") || 0;
          });

          this.orderCountAudiobook = orderCounter.toLocaleString("de");
          this.orderedProductsCountAudiobook =
            orderedProductsCounter.toLocaleString("de");
        });
    },

    getWPStats: async function () {
      const { backendToken } = await this.$authDocument;

      const self = this;
      console.log("get stats called");

      axios
        .get(
          `https://carlender2024.jp-superstore.de/wp-json/stats/${backendToken}`
        )
        .then(function (response) {
          self.statsCarlender2024 = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
