import axios from "axios";
import firebase from "firebase";

export async function getPaypalAccessToken() {
  // Get client data for PayPal API
  const { clientId, clientSecret } = await firebase
    .firestore()
    .collection("werkstatt-meta")
    .doc("paypal")
    .get()
    .then((doc) => ({
      clientId: doc.get("clientId"),
      clientSecret: doc.get("clientSecret"),
    }));

  // Get Bearer token from PayPal API
  const { data } = await axios.post(
    "https://api-m.paypal.com/v1/oauth2/token",
    "grant_type=client_credentials",
    {
      headers: {
        Accept: "application/json",
      },
      auth: {
        username: clientId,
        password: clientSecret,
      },
    }
  );

  if (!data || !data.access_token) {
    return;
  }

  return data.access_token;
}

export async function addTrackingInfoToPaypal(
  transactionId,
  trackingNo,
  paypalToken
) {
  let message,
    success = false;

  await axios
    .post(
      `https://api-m.paypal.com/v1/shipping/trackers-batch`,
      {
        trackers: [
          {
            transaction_id: transactionId,
            tracking_number: trackingNo,
            tracking_number_type: "CARRIER_PROVIDED",
            status: "SHIPPED",
            carrier: "DE_DHL_PACKET",
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${paypalToken}`,
        },
      }
    )
    .then(() => {
      message = `Add tracking info: ${trackingNo} to transactionId ${transactionId}`;
      success = true;
    })
    .catch((error) => {
      message = `Error updating PayPal tracking number for transactionId ${transactionId}: ${error}`;
    });

  await sleep(1000);
  return { message, success };
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
