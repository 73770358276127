<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Ausloggen</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Produkte</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Sync' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Sync" />
              <span class="nav-text">Sync</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Batches' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Batches</span>
            </router-link>
          </li>
          <!--<li class="nav-item">
            <router-link :to="{ name: 'Files' }" class="nav-item-hold">
                <i aria-hidden="true" class="nav-icon i-Suitcase"></i>
                <span class="nav-text">Dateien</span>
            </router-link>
            <div class="triangle"></div>
          </li>-->
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="breadcrumb">
        <h1>Daten verwalten</h1>
      </div>

      <div class="separator-breadcrumb border-top"></div>

      <div class="row">
        <div class="col-md-12 mb-4">
          <div class="card text-left">
            <div class="card-body">
              <h4 class="card-title mb-3">{{ data.name }} - Daten</h4>

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm"
                    >Suchen:</span
                  >
                </div>
                <input
                  @keyup="delay(search, 300)"
                  aria-describedby="inputGroup-sizing-sm"
                  aria-label="Search"
                  class="form-control"
                  id="search-input"
                  type="text"
                />
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table
                  class="display table table-striped table-bordered"
                  id="scroll_horizontal_table"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Aktion</th>

                      <th :key="fieldname" v-for="fieldname in field">
                        {{ fieldname }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(record, index) in records">
                      <td>
                        <router-link
                          :to="{
                            name: 'TableDetails',
                            params: { table: table, document: record.id },
                          }"
                        >
                          <span class="text-default mr-2">
                            <i
                              aria-hidden="true"
                              class="nav-icon i-Eye font-weight-bold"
                            />
                          </span>
                        </router-link>

                        <span
                          @click="editRecord(record.id)"
                          class="text-success mr-2"
                          style="cursor: pointer"
                        >
                          <i
                            aria-hidden="true"
                            class="nav-icon i-Pen-2 font-weight-bold"
                          />
                        </span>
                        <!--<span class="text-danger mr-2" @click="deleteRecord(record.id)" style="cursor: pointer;">
                          <i aria-hidden="true" class="nav-icon i-Close-Window font-weight-bold"></i>
                        </span>-->
                      </td>

                      <td :key="fieldname" v-for="fieldname in field">
                        <div v-if="fieldname === 'tracking_no'">
                          <a
                            target="_blank"
                            v-bind:href="
                              'https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=' +
                              record[0][fieldname]
                            "
                            >{{ record[0][fieldname] }}</a
                          >
                        </div>
                        <div v-else-if="fieldname === 'transaction_id'">
                          <a
                            target="_blank"
                            v-bind:href="
                              'https://www.paypal.com/signin?returnUri=https://www.paypal.com/activity/payment/' +
                              record[0][fieldname]
                            "
                            >{{ record[0][fieldname] }}</a
                          >
                        </div>
                        <div v-else-if="fieldname === 'wc_id'">
                          <a
                            target="_blank"
                            v-bind:href="
                              'https://carlender2024.jp-superstore.de/wp-admin/post.php?action=edit&post=' +
                              record[0][fieldname]
                            "
                          >
                            {{ record[0][fieldname] }}
                          </a>
                        </div>
                        <div v-else>
                          {{ record[0][fieldname] }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <nav aria-label="Navigation" id="pagination">
                <ul class="pagination">
                  <!--<li class="page-item"><a class="page-link" @click="modifiyPagination('-')">Zurück</a></li>-->
                  <li class="page-item">
                    <a @click="modifiyPagination('+')" class="page-link"
                      >Weiter</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="card-title mb-3">
                {{ data.title || data.name }}<br />
                <small>{{ data.title ? data.name : "" }}</small>
              </div>
              <form name="CustomTable">
                <div class="row">
                  <div
                    :key="index"
                    class="col-md-6 form-group mb-3"
                    v-for="(fieldname, index) in field"
                  >
                    <label>{{ fieldname }}</label>
                    <input
                      class="form-control form-control-rounded"
                      type="text"
                      v-bind:placeholder="'Enter ' + fieldname"
                      v-if="dataType[index] === 'String'"
                      v-model="newTable[fieldname]"
                    />
                    <input
                      class="form-control form-control-rounded"
                      type="number"
                      v-bind:placeholder="'Enter ' + fieldname"
                      v-if="dataType[index] === 'Number'"
                      v-model="newTable[fieldname]"
                    />
                  </div>

                  <div class="col-md-12">
                    <button
                      @click="addTable()"
                      class="btn btn-primary"
                      type="button"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";

export default {
  name: "ManageTable",

  data() {
    return {
      table: this.$router.currentRoute.params.id,
      photoURL: "",
      data: {},
      field: [],
      dataType: [],
      newTable: {},
      records: [],
      editKey: "",
      dataKey: [],
    };
  },

  created: function () {
    this.tableData();
    this.userData();
  },

  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },

    userData: function () {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.photoURL = user.photoURL;
      }
    },

    tableData: function () {
      const docRef = firebase.firestore().collection("tables").doc(this.table);
      let field, dataType, docData;
      const self = this;

      $.when(
        docRef.get().then(function (doc) {
          if (doc.exists) {
            docData = doc.data();
            field = doc.data().field;
            dataType = doc.data().dataType;
          }
        })
      ).then(function () {
        self.data = docData;
        self.field = field.split(",");
        self.dataType = dataType.split(",");
        self.tableRecords();
      });
    },

    delay: function (fn, ms) {
      let self = this;
      return (function (...args) {
        clearTimeout(self.timer);
        self.timer = setTimeout(fn.bind(this, ...args), ms || 0);
      })();
    },

    search: function () {
      let searchInput = jQuery("#search-input").val();
      if (searchInput.length > 0) {
        jQuery("#pagination").hide();
        this.records = [];
        const orderids = new Set();
        this.field.forEach((fieldname) => {
          let docRef = firebase
            .firestore()
            .collection(this.data.name)
            .where(fieldname, "==", searchInput)
            .limit(this.perPage);

          docRef.onSnapshot((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (!orderids.has(doc.id)) {
                orderids.add(doc.id);
                const a = [];
                a.id = doc.id;
                a.push(doc.data());
                this.records.push(a);
              }
            });
          });
        });
      }
    },

    modifiyPagination: function (direction) {
      this.tableRecords(direction);
    },

    addTable: function () {
      if (!this.editKey) {
        firebase.firestore().collection(this.data.name).add(this.newTable);
        swal({
          type: "success",
          title: "Success!",
          text: "Your record has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        firebase
          .firestore()
          .collection(this.data.name)
          .doc(this.editKey)
          .update(this.newTable);
        this.editKey = "";

        swal({
          type: "success",
          title: "Success!",
          text: "Your record has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      this.newTable = {};
    },

    tableRecords: function (direction = false) {
      let self = this;
      if (!this.position) {
        this.perPage = 100;
      }

      let docRef = firebase.firestore().collection(this.data.name).limit(10);

      if (direction !== false && !!this.lastVisible) {
        console.log("set direction", direction);
        switch (direction) {
          case "+":
            console.log(
              "start at last visible",
              this.lastVisible.get("firstname")
            );
            docRef = docRef.startAt(this.lastVisible);
            break;
          case "-":
            console.log(
              "start at first visible",
              this.firstVisible.get("firstname")
            );
            docRef = docRef.startAt(this.firstVisible);
            break;
        }
      }

      docRef.onSnapshot((querySnapshot) => {
        self.firstVisible = querySnapshot.docs[0];
        self.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        this.records = [];

        querySnapshot.forEach((doc) => {
          const a = [];

          a.id = doc.id;
          a.push(doc.data());

          this.records.push(a);
        });
      });
    },

    deleteRecord: function (key) {
      const name = this.data.name;
      const self = this;
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#0CC27E",
        cancelButtonColor: "#FF586B",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        confirmButtonClass: "btn btn-success mr-5",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: !1,
      }).then(
        function () {
          firebase.firestore().collection(name).doc(key).delete();

          self.tableRecords();

          swal("Deleted!", "Your record has been deleted.", "success");
        },
        function (t) {
          "cancel" === t &&
            swal("Cancelled", "Your record is safe :)", "error");
        }
      );
    },

    editRecord: function (key) {
      const self = this;
      const docRef = firebase.firestore().collection(this.data.name).doc(key);

      docRef.get().then(function (doc) {
        if (doc.exists) {
          self.newTable = doc.data();
          self.editKey = key;
        }
      });
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
