<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Sign out</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'ManageTable', params: { id: '2023_4-carlender' } }"
              class="nav-item-hold"
            >
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Carlender 2024</span>
            </router-link>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Produkte</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Sync' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Sync" />
              <span class="nav-text">Sync</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Batches' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Batches</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <!--=============== Left side End ================-->

    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <nav class="breadcrumb">
        <h1>Bestellungsdetails</h1>
      </nav>

      <div class="row" style="margin-left: 0">
        <h5>
          Dokument: <code>{{ document }}</code>
        </h5>
      </div>

      <div class="row" style="margin-left: 0">
        <h5>
          Tabelle: <code>{{ tableName || table }}</code>
        </h5>
      </div>

      <div class="separator-breadcrumb border-top"></div>

      <div class="container" v-if="hasShipmentStatus">

        <div class="row">
          <div class="col-md-6 mb-4">
            <div class="card shadow p-3 mb-5 bg-white rounded">
              <div class="card-body">
                <span class="text-info" style="font-size: 32px">
                  <i aria-hidden="true" class="i-Tag" />

                  <span v-if="orderInfo.batch && orderInfo.batch.ausgeliefert">
                    {{ orderInfo.batch.ausgeliefert }}
                  </span>
                  <span v-else> Nicht ausgeliefert </span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card shadow p-3 mb-5 bg-white rounded">
              <div class="card-body">
                <span class="text-info" style="font-size: 32px">
                  <i aria-hidden="true" class="i-Optimization" />
                  {{ orderInfo.status }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container"
        v-if="documentValues.payment_status == 'REFUND-REQUESTED'"
      >
        <div class="mx-auto mb-4">
          <div class="card shadow mb-5 bg-white rounded">
            <div class="card-body">
              <span class="text-danger" style="font-size: 20px">
                Der Kunde bittet um eine Stornierung seiner Bestellung.<br />
                Bitte bearbeite diese in den
                <a :href="'/#/cancellationRequests/' + this.tableName"
                  >Stornierungsanfragen</a
                >.<br />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="mx-auto mb-4">
          <div class="card shadow mb-5 bg-white rounded">
            <div class="card-body">
              <div aria-label="Third group" class="btn-group-lg" role="group">
                <button
                  :disabled="documentValues.customer_verified"
                  @click="markOrderAsVerified"
                  class="btn btn-success m-2"
                  type="button"
                  v-if="orderInfo.status === 'invalid-address'"
                >
                  <i
                    aria-hidden="true"
                    class="i-Check"
                    style="font-weight: bold"
                  />
                  {{
                    !documentValues.customer_verified
                      ? "Daten als bestätigt markieren"
                      : "Daten wurden als bestätigt markiert"
                  }}
                </button>

                <!-- <button type="button" class="btn btn-info m-2">
                  <i style="font-weight: bold;" class="i-Tag-3"></i>
                  Retouren Label erzeugen
                </button> -->

                <button
                  v-if="orderInfo.status"
                  :disabled="
                    !orderInfoButtonEnabled ||
                    (orderInfo.status !== 'in-batch' &&
                      orderInfo.status !== 'in-shipping' &&
                      orderInfo.status !== 'is-shipped' &&
                      orderInfo.status !== 'completed')
                  "
                  @click="orderInfoToggle"
                  class="btn btn-primary m-2"
                  type="button"
                >
                  <i
                    class="i-Turn-Down-From-Right"
                    style="font-weight: bold"
                  ></i>
                  {{
                    orderInfo.status === "in-batch" ||
                    orderInfo.status === "in-shipping" ||
                    orderInfo.status === "is-shipped" ||
                    orderInfo.status === "completed"
                      ? "Als zurückgekommen markieren"
                      : "Bestellung ist zurückgekommen"
                  }}
                </button>

                <template
                  v-if="labelRequested && orderInfo.status === 'came-back'"
                >
                  <button
                    :disabled="!labelButtonEnabled"
                    @click="labelRemove"
                    class="btn btn-danger m-2"
                    type="button"
                    v-if="label"
                  >
                    <i
                      aria-hidden="true"
                      class="i-Close"
                      style="font-weight: bold"
                    />
                    DHL Label löschen
                  </button>

                  <button
                    :disabled="!labelButtonEnabled"
                    @click="labelGenerate"
                    class="btn btn-warning m-2"
                    type="button"
                    v-else
                  >
                    <i
                      aria-hidden="true"
                      class="i-Repeat-2"
                      style="font-weight: bold"
                    />
                    DHL Label generieren
                  </button>
                </template>

                <a
                  :href="label.label_url"
                  class="btn btn-info m-2"
                  target="_blank"
                  v-if="label && label.label_url"
                >
                  <i
                    aria-hidden="true"
                    class="i-Download"
                    style="font-weight: bold"
                  />
                  DHL Label herunterladen
                </a>

                <a
                  :href="invoiceUrl"
                  class="btn btn-dark m-2"
                  target="_self"
                  v-if="invoiceUrl && documentValues && documentValues.orderid"
                >
                  <i
                    aria-hidden="true"
                    class="i-Download"
                    style="font-weight: bold"
                  />
                  Rechung herunterladen
                </a>

                <a
                  :href="paceTicketsUrl"
                  class="btn btn-dark m-2"
                  target="_self"
                  v-if="
                    paceTicketsUrl && documentValues && documentValues.orderid
                  "
                >
                  <i
                    aria-hidden="true"
                    class="i-Ticket"
                    style="font-weight: bold"
                  />
                  Tickets herunterladen
                </a>

                <div v-if="documentValues.downloadable_code">
                  <h4 class="my-2">Hörbuch-Code</h4>
                  <code style="font-size: 1rem;">
                    <a :href="'https://jpu.amicaldo.net/dashboard/plugins/content-manager/collectionType/application::audio-book-code.audio-book-code?page=1&pageSize=20&_sort=id:ASC&_where[0][code]=' + documentValues.downloadable_code">
                      {{ documentValues.downloadable_code }}
                    </a>
                  </code>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" v-if="documentValues.payment_status == 'VERIFIED'">
        <div class="mx-auto mb-4">
          <div class="card shadow mb-5 bg-white rounded">
            <div class="card-body">
              <div class="input-group mx-auto mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    Warum soll diese Bestellung <br />
                    storniert werden?
                  </span>
                </div>
                <textarea
                  v-model="cancellationReason"
                  rows="3"
                  class="form-control"
                ></textarea>
              </div>

              <div aria-label="Third group" class="btn-group-lg" role="group">
                <button
                  @click="markOrderAsRefundRequested"
                  class="btn btn-danger m-2"
                  type="button"
                >
                  <i
                    aria-hidden="true"
                    class="i-Repeat-3"
                    style="font-weight: bold"
                  />

                  Stornierung vormerken
                </button>
              </div>

              <div>
                <p class="h6 mt-2">
                  Bitte vorgemerkte Bestellungen über das Dashboard
                  ("Stornierungsanfragen") bearbeiten, zurückzahlen und &nbsp;
                  <strong>bestätigen</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" v-if="documentValues.payment_status == 'VERIFIED'">
        <div class="mx-auto mb-4">
          <div class="card shadow mb-5 bg-white rounded">
            <div class="card-body">
              <div class="input-group mx-auto mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    Notizen an Bestellung hinterlegen
                  </span>
                </div>
                <textarea
                  v-model="newOrderNote"
                  rows="3"
                  class="form-control"
                ></textarea>
              </div>

              <div aria-label="Third group" class="btn-group-lg" role="group">
                <button
                  @click="saveOrderNote"
                  class="btn btn-primary m-2"
                  type="button"
                >
                  <i
                    aria-hidden="true"
                    class="i-Data-Save"
                    style="font-weight: bold"
                  />

                  Notiz hinzufügen
                </button>
              </div>

              <div>
                <p class="h6 mt-2">
                  Bitte E-Mails, Aktionen und wissenswertes hier reinschreiben.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row mb-4">
          <div class="col-xl-6 mb-4">
            <div
              class="card shadow p-3 mb-5 bg-white rounded"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Persönliche Daten</h2>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="firstname lastname">
                      <i aria-hidden="true" class="i-Male mr-3" />
                      <strong>Name:</strong>
                      {{ documentValues.firstname || "–" }}
                      {{ documentValues.lastname || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="street, plz city">
                      <i aria-hidden="true" class="i-Home1 mr-3" />
                      <strong>Anschrift:</strong>
                      {{ documentValues.street || "–" }}
                      {{ documentValues.street_number || "–" }},
                      {{ documentValues.plz || "–" }}
                      {{ documentValues.city || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="country">
                      <i aria-hidden="true" class="i-Flag-2 mr-3" />
                      <strong>Land:</strong>
                      {{ documentValues.country || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="company">
                      <i aria-hidden="true" class="i-Office-Lamp mr-3" />
                      <strong>Firma:</strong>
                      {{ documentValues.company || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="email">
                      <i aria-hidden="true" class="i-Mail mr-3" />
                      <strong>E-Mail:</strong>

                      <a :href="`mailto:${documentValues.email}`">
                        {{ documentValues.email || "–" }}
                      </a>
                    </h4>
                  </div>
                  <div class="py-1">
                    <h4 title="phone">
                      <i aria-hidden="true" class="i-Plastic-Cup-Phone mr-3" />
                      <strong>Telefon:</strong>

                      <a :href="`mailto:${documentValues.phone}`">
                        {{ documentValues.phone || "–" }}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 mb-4">
            <div
              class="card shadow p-3 mb-5 bg-white rounded"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Versanddetails</h2>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="ship_firstname ship_lastname">
                      <i aria-hidden="true" class="i-Male mr-3" />
                      <strong>Name:</strong>

                      {{ documentValues.ship_firstname }}
                      {{ documentValues.ship_lastname }}

                      <span
                        v-if="
                          !documentValues.ship_firstname &&
                          !documentValues.ship_lastname
                        "
                      >
                        –
                      </span>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="ship_city ship_street">
                      <i aria-hidden="true" class="i-Home1 mr-3" />
                      <strong>Anschrift:</strong>

                      {{ documentValues.ship_street || "–" }}
                      {{ documentValues.ship_street_number || "–" }},
                      {{ documentValues.ship_plz || "–" }}
                      {{ documentValues.ship_city || "–" }}

                      <span
                        v-if="
                          !documentValues.ship_street &&
                          !documentValues.ship_city
                        "
                      >
                        –
                      </span>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="ship_country">
                      <i aria-hidden="true" class="i-Flag-2 mr-3" />
                      <strong>Land:</strong>

                      {{ documentValues.ship_country || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="ship_company">
                      <i aria-hidden="true" class="i-Office-Lamp mr-3" />
                      <strong>Firma:</strong>

                      {{ documentValues.ship_company || "–" }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-xl-6 mb-4">
            <div
              class="card shadow p-3 mb-5 bg-white rounded"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Bestelldetails</h2>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="amount">
                      <i aria-hidden="true" class="i-Bag-Items mr-3" />
                      <strong>Anzahl:</strong>

                      {{ documentValues.amount }}x
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="orderid">
                      <i aria-hidden="true" class="i-Fingerprint mr-3" />
                      <strong>OID: </strong>

                      <code>{{ documentValues.orderid }}</code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="tracking_no">
                      <i aria-hidden="true" class="i-Security-Camera mr-3" />
                      <strong>TrackingNr: </strong>

                      <code>
                        <a
                          target="_blank"
                          v-bind:href="
                            'https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=' +
                            (label ? label.tracking_no : '')
                          "
                          >{{ label ? label.tracking_no : "–" }}</a
                        ></code
                      >
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="wc_id">
                      <i aria-hidden="true" class="i-Wordpress mr-3" />
                      <strong>WCID: </strong>

                      <code>
                        <a
                          target="_blank"
                          v-bind:href="
                            apiBase +
                            '/wp-admin/post.php?action=edit&post=' +
                            documentValues.wc_id
                          "
                          >{{ documentValues.wc_id || "–" }}
                        </a>
                      </code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="order_date">
                      <i aria-hidden="true" class="i-Clock mr-3" />
                      <strong>Datum:</strong>

                      {{ documentValues.order_date || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="tracking_no">
                      <i aria-hidden="true" class="i-Security-Camera mr-3" />
                      <strong>Übermittlung an Versand: </strong>

                      <code>
                        {{
                          orderInfo.batch ? orderInfo.batch.delivery_date : "–"
                        }}
                      </code>
                    </h4>
                  </div>
                </div>

                <div
                  class="alert alert-warning mt-3 mb-0"
                  role="alert"
                  v-if="!apiBase"
                >
                  Das Backend für diese Bestellung konnte nicht gefunden werden.
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 mb-4">
            <div
              class="card shadow p-3 mb-5 bg-white rounded"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="card-title">
                  <h2>Zahlungsdetails</h2>
                </div>

                <div class="text-left" style="margin: auto">
                  <div class="py-1">
                    <h4 title="payment_status">
                      <i aria-hidden="true" class="i-Checkout mr-3" />
                      <strong>Status:</strong>

                      <code
                        style="color: green"
                        v-if="documentValues.payment_status === 'VERIFIED'"
                      >
                        {{ documentValues.payment_status }}
                      </code>

                      <code
                        style="color: red"
                        v-else-if="documentValues.payment_status === 'CONFLICT'"
                      >
                        {{ documentValues.payment_status }}
                      </code>

                      <code v-else>
                        {{ documentValues.payment_status || "–" }}
                      </code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="payment_id">
                      <i aria-hidden="true" class="i-Receipt-3 mr-3" />
                      <strong>PaymentID: </strong>

                      <code>{{ documentValues.payment_id || "–" }}</code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="transaction_id">
                      <i aria-hidden="true" class="i-Cash-Register mr-3" />
                      <strong>TransactionID: </strong>

                      <code>{{ documentValues.transaction_id || "–" }}</code>
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="payment_date">
                      <i aria-hidden="true" class="i-Clock mr-3" />
                      <strong>Datum:</strong>

                      {{ documentValues.payment_date || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="price_per_item">
                      <i class="i-Euro mr-3" />
                      <strong>Verkaufspreis pro Stück:</strong>

                      {{ documentValues.price_per_item || "–" }}
                    </h4>
                  </div>

                  <div class="py-1">
                    <h4 title="payment_date">
                      <i class="i-Box1 mr-3" />
                      <strong>Versandkosten:</strong>

                      {{ documentValues.shipping_costs || "–" }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="container" v-if="documentValues.wc_id != ''">
          <div class="mx-auto mb-4">
            <div class="card shadow mb-5 bg-white rounded">
              <div class="card-body">
                <div class="card-title">
                  <h2>Aktivitätsverlauf</h2>
                </div>
                <div class="table-responsive">
                  <table class="display table table-striped table-bordered">
                    <tr v-for="note in orderInfo.order_notes" :key="note.key">
                      <td class="text-nowrap">
                        {{ formatDate(note.date_created.date) }}
                      </td>
                      <td class="text-left" v-html="note.content">
                        {{ note.content }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";
import axios from "axios";

export default {
  name: "TableDetails",

  data() {
    return {
      $authDocument: null,

      table: this.$router.currentRoute.params.table,
      document: this.$router.currentRoute.params.document,
      tableName: "",
      tableFields: [],
      documentValues: [],
      invoiceBaseUrl: "https://invoice-service.jp-superstore.de",
      invoiceUrl: "",
      paceTicketsBaseUrl: "https://pace-ticket-service.jp-superstore.de",
      paceTicketsUrl: "",
      cancellationReason: "",
      newOrderNote: "",
      apiBaseMapping: {
        "2021_1-audiobook-orders": "https://2020_1-audiobook.jp-superstore.de",
        "2020_3-carlender-orders": "https://carlender2020.jp-superstore.de",
        "2020_2-biografie-orders": "https://book2020.jp-superstore.de",
        "2021_2-carlender-orders": "https://carlender2022.jp-superstore.de",
        "2021_3-biografie-orders": "https://book2020.jp-superstore.de",
        "2022_1-boostwars-orders": "https://boostwars2022.jp-superstore.de",
        "2022_2-carlender-orders": "https://carlender2023.jp-superstore.de",
        "2023_4-carlender-orders": "https://carlender2024.jp-superstore.de",
      },
      apiBase: "",

      label: undefined,
      labelRequested: false,
      labelButtonEnabled: true,

      orderInfo: {
        batch: {},
      },
      orderInfoRequested: false,
      orderInfoButtonEnabled: true,
      hasShipmentStatus: false,
      tablesWithoutShipmentStatus: [
        '2021_1-audiobook-orders',
        'pace-ticket-orders'
      ]
    };
  },

  created: function () {
    this.initMetadata();
    this.tableInfo();
  },

  methods: {
    initMetadata: async function () {
      this.$authDocument = firebase
        .firestore()
        .collection("werkstatt-meta")
        .doc("auth")
        .get()
        .then((doc) => ({ ...doc.data(), docId: doc.id }));
    },

    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },

    /**
     * Get info about current table
     */
    tableInfo: function () {
      firebase
        .firestore()
        .collection("tables")
        .doc(this.table)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.tableName = doc.data().name;
            this.apiBase = this.apiBaseMapping[this.tableName];
            this.tableFields = doc.data().field.split(",");
            this.hasShipmentStatus = !this.tablesWithoutShipmentStatus.includes(doc.data().name);
            this.getDocument();
          } else {
            console.error("Table not found:", this.table);
          }
        })
        .catch((err) => {
          console.error("Error getting table", err);
        });
    },

    digestToken: async function (str) {
      const msgUint8 = new TextEncoder().encode(str.toLowerCase());
      const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    },

    /**
     * Get current document
     */
    getDocument: function () {
      firebase
        .firestore()
        .collection(this.tableName)
        .doc(this.document)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.documentValues = doc.data();
            this.orderNotesArray = this.documentValues.order_notes || [];
            this.labelGet();
            this.orderInfoGet();
          } else {
            console.error("Document not found:", this.document);
          }
        })
        .then(async () => {
          const orderId = this.documentValues.orderid;
          const mtoken = await this.digestToken(
            this.documentValues.email
          ).catch(() => "");
          const invoiceData = await axios
            .get(
              `${this.invoiceBaseUrl}/orders/${orderId}/invoice?mtoken=${mtoken}`
            )
            .catch(() => null);

          if (invoiceData) {
            this.invoiceUrl = `${this.invoiceBaseUrl}/orders/${orderId}/invoice/download?mtoken=${mtoken}`;
          }

          if (this.tableName === "pace-ticket-orders") {
            this.paceTicketsUrl = `${this.paceTicketsBaseUrl}/orders/${orderId}/tickets?mtoken=${mtoken}`;
          }
        })
        .catch((err) => {
          console.error("Error getting document", err);
        });
    },

    /**
     * Mark an order as verified and update info in Firebase
     */
    markOrderAsVerified: function () {
      this.$set(this.documentValues, "customer_verified", true);

      firebase
        .firestore()
        .collection(this.tableName)
        .doc(this.document)
        .update({ customer_verified: true });
    },

    /**
     * Get order information
     */
    orderInfoGet: async function () {
      const data = await this.orderInfoQueryExec("get");

      this.orderInfo = data ? data : {};

      this.orderInfo.order_notes.concat(this.orderNotesArray);
    },

    /**
     * Toggle order status between completed and processing
     */
    orderInfoToggle: async function () {
      this.orderInfoButtonEnabled = false;

      const data = await this.orderInfoQueryExec("post", {
        status:
          this.orderInfo.status === "in-batch" ||
          this.orderInfo.status === "in-shipping" ||
          this.orderInfo.status === "is-shipped" ||
          this.orderInfo.status === "completed"
            ? "came-back"
            : "is-shipped",
      });

      this.orderInfo = data ? data : {};

      this.orderInfoButtonEnabled = true;
    },

    /**
     * Execute API call for get and update order info
     */
    orderInfoQueryExec: async function (method, data = {}) {
      try {
        const { backendToken } = await this.$authDocument;

        const res = await axios[method](
          `${this.apiBase}/wp-json/jp/order_info/${this.documentValues.orderid}?token=${backendToken}`,
          data
        );

        return res.data;
      } catch (err) {
        console.log(err);
      }
    },

    /**
     * Get label
     */
    labelGet: async function () {
      this.label = await this.labelQueryExec("get");
      this.labelRequested = true;
    },

    /**
     * Generate label
     * @returns {Promise<void>}
     */
    labelGenerate: async function () {
      this.labelButtonEnabled = false;

      try {
        const data = await this.labelQueryExec("post");

        this.label = data;
        this.$set(this.documentValues, "tracking_no", data.tracking_no);
        this.labelRequested = true;

        await firebase
          .firestore()
          .collection(this.tableName)
          .doc(this.document)
          .update({
            tracking_no: this.label.tracking_no,
          });
      } catch (err) {
        console.log(err);
      } finally {
        this.labelButtonEnabled = true;
      }
    },

    /**
     * Remove label
     * @returns {Promise<void>}
     */
    labelRemove: async function () {
      this.labelButtonEnabled = false;

      try {
        await this.labelQueryExec("delete");

        this.label = undefined;
        this.$set(this.documentValues, "tracking_no", "");

        await firebase
          .firestore()
          .collection(this.tableName)
          .doc(this.document)
          .update({
            tracking_no: "",
            tracking_no_deleted: this.documentValues.tracking_no,
          });
      } catch (err) {
        console.log(err);
      } finally {
        this.labelButtonEnabled = true;
      }
    },

    /**
     * Execute API call for get, update, remove labels
     * @param method
     * @returns {Promise<*>}
     */
    labelQueryExec: async function (method) {
      try {
        const { backendToken } = await this.$authDocument;

        const res = await axios[method](
          `${this.apiBase}/wp-json/jp/labels/${this.documentValues.orderid}?token=${backendToken}`
        );

        return res.data;
      } catch (err) {
        console.log(method, err);
      }
    },

    markOrderAsRefundRequested: function () {
      const message = "Willst du diese Bestellung wirklich stornieren?";

      if (confirm(message)) {
        this.$set(this.documentValues, "payment_status", "REFUND-REQUESTED");

        firebase
          .firestore()
          .collection(this.tableName)
          .doc(this.document)
          .update({
            payment_status: "REFUND-REQUESTED",
            cancellation_reason: this.cancellationReason,
          })
          .then(() => (this.cancellationReason = ""));
      }
    },

    saveOrderNote: function () {
      console.log("ordernotes", this.orderNotesArray);
      this.orderNotesArray.push(this.newOrderNote);
      firebase
        .firestore()
        .collection(this.tableName)
        .doc(this.document)
        .update({
          order_notes: this.orderNotesArray,
        })
        .then(() => {
          this.newOrderNote = "";
        });
    },

    formatDate: function (dateString) {
      const date = new Date(dateString.split(" ")[0]);
      return date.toLocaleDateString("de-DE");
    },
  },
};
</script>
