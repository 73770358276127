<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Sign out</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Produkte</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Sync' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Sync" />
              <span class="nav-text">Sync</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Batches' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Batches</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <!--=============== Left side End ================-->

    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <nav class="breadcrumb">
        <h1>Synchronisation</h1>
      </nav>

      <div class="separator-breadcrumb border-top"></div>

      <div class="container">
        <div class="mx-auto mb-4">
          <div class="card shadow mb-5 bg-white rounded">
            <div class="card-body">
              <div class="card-title">
                <h2>Trackingnummern + wc_id in Firebase importieren</h2>

                <h3>Carlender 2024 → 2023_4-carlender</h3>
              </div>
              <div class="table-responsive">
                <button
                  class="btn btn-primary"
                  :disabled="importRunning"
                  @click="startImportFirebaseTrackingNos(productId)"
                >
                  Import starten
                </button>

                <pre
                  style="height: 18rem; overflow-y: scroll"
                  class="mt-4 bg-gray-200 text-left"
                  >{{ firebaseTrackingNoImporterMessages }}</pre
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="mx-auto mb-4">
          <div class="card shadow mb-5 bg-white rounded">
            <div class="card-body">
              <div class="card-title">
                <h2>Trackingnummern in PayPal importieren</h2>

                <h3>2023_4-carlender → PayPal</h3>
              </div>
              <div class="table-responsive">
                <button
                  class="btn btn-primary"
                  :disabled="importRunning"
                  @click="startImportPaypalTrackingNos(productId)"
                >
                  Import starten
                </button>

                <pre
                  style="height: 18rem; overflow-y: scroll"
                  class="mt-4 bg-gray-200 text-left"
                  >{{ paypalTrackingNoImporterMessages }}</pre
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";
import axios from "axios";
import {
  getOrdersWithoutTracking,
  getCollectionRef,
  getOrdersWithTrackingNo,
} from "../services/order.service";
import {
  addTrackingInfoToPaypal,
  getPaypalAccessToken,
} from "../services/paypal.service";

export default {
  name: "TableDetails",

  data() {
    return {
      productId: "2023_4-carlender",
      backendToken: "",
      importRunning: false,
      firebaseTrackingNoImporterMessages: "### Tracking Importer ###",
      paypalTrackingNoImporterMessages: "### PayPal Exporter ###",
    };
  },

  created: function () {
    this.getBackendAuthToken();
  },

  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },

    userData: function () {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.photoURL = user.photoURL;
      }
    },

    getLogger: function (importer) {
      return (message) => {
        const attr = `${importer}ImporterMessages`;

        this[attr] = message + "\n" + this[attr];
      };
    },

    getBackendAuthToken: function () {
      firebase
        .firestore()
        .collection("werkstatt-meta")
        .doc("auth")
        .get()
        .then((doc) => (this.backendToken = doc.get("backendToken")));
    },

    getFirebaseProductMeta: async function (productId) {
      return firebase.firestore().collection("tables").doc(productId).get();
    },

    startImportFirebaseTrackingNos: async function (productId) {
      this.importRunning = true;
      const logger = this.getLogger("firebaseTrackingNo");

      logger("Lade Firebase-Informationen...");

      const productMeta = await this.getFirebaseProductMeta(productId);

      const backendApiBase = productMeta.get("backendApiBase");
      const productCollection = productMeta.get("name");
      const collectionRef = getCollectionRef(productCollection);

      logger(`Backend API-Base: ${backendApiBase}`);
      logger(`Firebase Product-Collection: ${productCollection}`);

      if (!productCollection || !collectionRef) {
        logger("Fehler: Keine Product-Collection gefunden!");
        return;
      }

      if (!backendApiBase) {
        logger("Fehler: Keine API-Base gefunden!");
        return;
      }

      logger("Lade Tracking-Informationen...");

      await axios
        .get(`${backendApiBase}/tracking/${this.backendToken}`)
        .then(async ({ data }) => {
          logger("Starte Import zu Firebase...");

          await collectionRef
            .where("tracking_no", "==", "")
            .limit(150)
            .get()
            .then((orders) => {
              iterateResults(orders, data);
            })
            .catch((error) => {
              console.error(error);
              logger(`Error getting documents: ${error}`);
            });

          function iterateResults(orders, wpdata) {
            var lastVisible = orders.docs[orders.docs.length - 1];
            let count = 0;
            orders.forEach((order) => {
              let orderid = order.get("orderid");

              if (!order.get("tracking_no") || !order.get("wc_id")) {
                if (wpdata && wpdata[orderid]) {
                  let row = wpdata[orderid];

                  collectionRef.doc(order.id).update({
                    tracking_no: row["tracking_no"],
                    wc_id: row["wc_id"],
                  });
                  logger(
                    `Add tracking info: ${row["tracking_no"]} to orderId ${row["orderid"]}`
                  );
                } else {
                  logger(
                    `didn't found tracking info in wordpress for ${orderid}`
                  );
                }
              } else {
                console.log("tracking no already set");
              }

              count++;
              if (count === 149) {
                console.log("get next page");
                getAdditional(lastVisible)
                  .get()
                  .then(function (results) {
                    iterateResults(results, wpdata);
                  });
              }
            });
          }

          function getAdditional(lastVisible) {
            return collectionRef
              .where("tracking_no", "==", "")
              .startAt(lastVisible)
              .limit(150);
          }
        })
        .catch((err) => {
          logger(`Fehler beim Abruf der Tracking-Daten: ${err}`);
        });

      logger("Ende des Imports!");
      logger("");
      this.importRunning = false;
    },

    startImportPaypalTrackingNos: async function (productId) {
      this.importRunning = true;
      const logger = this.getLogger("paypalTrackingNo");

      logger("Initialisiere PayPal-Verbindung...");

      const paypalToken = await getPaypalAccessToken();

      if (!paypalToken) {
        logger("Fehler: Konnte keinen Token von PayPal abfragen!");
        return;
      }

      logger("Lade Firebase-Informationen...");

      const productMeta = await this.getFirebaseProductMeta(productId);

      const productCollection = productMeta.get("name");
      const collectionRef = getCollectionRef(productCollection);

      logger(`Firebase Product-Collection: ${productCollection}`);

      if (!productCollection || !collectionRef) {
        logger("Fehler: Keine Product-Collection gefunden!");
        return;
      }

      const orders = (await getOrdersWithTrackingNo(collectionRef)).docs;

      logger("Bestellungen wurden aus Firebase geladen. Starte Import...");

      for (const orderDoc of orders) {
        const transactionId = orderDoc.get("transaction_id");
        const trackingNo = orderDoc.get("tracking_no");
        const alreadyProcessed = orderDoc.get("processed_paypal_tracking_sync");

        if (!transactionId || !trackingNo || alreadyProcessed) {
          continue;
        }

        const { message, success } = await addTrackingInfoToPaypal(
          transactionId,
          trackingNo,
          paypalToken
        );

        logger(message);

        if (success) {
          await collectionRef.doc(orderDoc.id).update({
            processed_paypal_tracking_sync: true,
          });
        }
      }

      logger("Ende des Imports!");
      logger("");
      this.importRunning = false;
    },
  },
};
</script>
