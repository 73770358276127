<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Ausloggen</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Produkte</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Sync' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Sync" />
              <span class="nav-text">Sync</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Batches' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Batches</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>

    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="table-responsive">
        <table
          class="display table table-striped table-bordered"
          id="scroll_horizontal_table"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Telefonnummer</th>
              <th>E-Mail</th>
              <th>Begründung</th>
              <th>Transaction ID</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="order" v-for="order in orders">
              <td>{{ order.firstname }} {{ order.lastname }}</td>
              <td>
                <a :href="'tel:' + order.phone">{{ order.phone }}</a>
              </td>
              <td>
                <a :href="'mailto:' + order.email">{{ order.email }}</a>
              </td>
              <td>
                {{ order.cancellation_reason || "–" }}
              </td>
              <td>
                <a
                  v-if="order.transaction_id"
                  target="_blank"
                  :href="
                    'https://www.paypal.com/signin?returnUri=https://www.paypal.com/activity/payment/' +
                    order.transaction_id
                  "
                >
                  {{ order.transaction_id }}
                </a>

                <a v-else> – </a>
              </td>

              <td>
                <button
                  @click="checkOrderStatus(order)"
                  class="btn btn-rounded btn-info btn-block"
                  type="button"
                  :disabled="order.checkingStatus"
                  v-if="!checking && !order.statusText"
                >
                  Status wird geprüft...
                </button>

                <span v-else v-html="order.statusText"> </span>
              </td>

              <td class="d-flex">
                <button
                  @click="markAsRefunded(order)"
                  class="btn btn-rounded btn-success btn-block m-0 mr-1"
                  type="button"
                >
                  Zurückgezahlt
                </button>

                <button
                  @click="markAsRejected(order)"
                  class="btn btn-rounded btn-danger btn-block m-0"
                  type="button"
                >
                  Abgelehnt
                </button>
                <br />
                <button
                  @click="shippingStopped(order)"
                  class="btn btn-rounded btn-warning btn-block m-0"
                  type="button"
                  v-if="order.stoppable"
                >
                  Versand gestoppt
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";
import axios from "axios";

export default {
  name: "CancellationRequests",

  data() {
    return {
      $authDocument: null,

      table: this.$router.currentRoute.params.id,
      apiBaseMapping: {
        "2021_1-audiobook-orders": "https://2020_1-audiobook.jp-superstore.de",
        "2020_3-carlender-orders": "https://carlender2020.jp-superstore.de",
        "2020_2-biografie-orders": "https://book2020.jp-superstore.de",
        "2021_2-carlender-orders": "https://carlender2022.jp-superstore.de",
        "2021_3-biografie-orders": "https://book2020.jp-superstore.de",
        "2022_1-boostwars-orders": "https://boostwars2022.jp-superstore.de",
        "2022_2-carlender-orders": "https://carlender2023.jp-superstore.de",
        "2023_4-carlender-orders": "https://carlender2024.jp-superstore.de",
      },
      apiBase: "",
      orders: [],
    };
  },

  created: function () {
    this.initMetadata();
    this.apiBase = this.apiBaseMapping[this.table];
    this.loadOrders();
  },

  methods: {
    initMetadata: async function () {
      this.$authDocument = firebase
        .firestore()
        .collection("werkstatt-meta")
        .doc("auth")
        .get()
        .then((doc) => ({ ...doc.data(), docId: doc.id }));
    },

    loadOrders: function () {
      firebase
        .firestore()
        .collection(this.table)
        .where("payment_status", "==", "REFUND-REQUESTED")
        .onSnapshot((querySnapshot) => {
          const orders = [];

          querySnapshot.forEach((doc) => {
            const docData = doc.data();

            docData.id = doc.id;
            docData.statusText = "";
            docData.checkingStatus = false;

            this.checkOrderStatus(docData);

            orders.push(docData);
          });

          this.orders = orders;
        });
    },

    checkOrderStatus: async function (order) {
      const { backendToken } = await this.$authDocument;

      order.checkingStatus = true;

      axios
        .get(
          `${this.apiBase}/wp-json/jp/order_info/${order.orderid}?token=${backendToken}`
        )
        .then(({ data }) => {
          const editLink = `${this.apiBase}/wp-admin/post.php?post=${data.wc_id}&action=edit`;

          if (data.batch && data.batch.ausgeliefert) {
            if (!order.order_status) {
              order.order_status = "Beim Versanddienstleister";
              order.stoppable = true;
            }
            order.statusText = `
              Achtung: <a target="_blank" href="${editLink}">Die Bestellung</a> befindet sich in Batch
              ${data.batch.ausgeliefert} als <strong>${data.batch.package_number}</strong> und im Status "${data.status}".
              Diese Bestellung wird versendet und ist ggf. nicht mehr zu stoppen. Versand Status: ${order.order_status}
            `;
          } else {
            order.statusText = `
              Der Status <a target="_blank" href="${editLink}">der Bestellung</a> ist "${data.status}".
            `;
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            order.statusText = `
              Die Bestellung wurde bislang nicht vorverarbeitet.
            `;
          } else {
            order.statusText = `
              Beim Abfragen des Bestellstatus ist ein unerwarteter Fehler augfetreten.
            `;
          }
        })
        .finally(() => {
          order.checkingStatus = false;
        });
    },

    markAsRefunded: function (order) {
      const message = `
        Hast du die Bestellung zurückgezahlt?
      `;

      if (confirm(message)) {
        firebase.firestore().collection(this.table).doc(order.id).update({
          payment_status: "REFUNDED",
          processed_weclapp_sync: false,
        });
      }
    },

    markAsRejected: function (order) {
      const message = `
        Willst du die Rückerstattung wirklich ablehnen?
        Damit geht ein Paket an diesen Kunden raus.
      `;

      if (confirm(message)) {
        firebase.firestore().collection(this.table).doc(order.id).update({
          payment_status: "VERIFIED",
        });
      }
    },

    shippingStopped: function (order) {
      const message = `
        Wurde diesem Paket im Versandprozess gestoppt?
      `;

      if (confirm(message)) {
        firebase.firestore().collection(this.table).doc(order.id).update({
          order_status: "SHIPPING_STOPPED",
        });
        //@TODO: das lädt die ganze liste neu und sieht doof aus.
        order.statusText = order.statusText + ". VERSAND ERFOLGREICH GESTOPPT";
      }
    },

    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },
  },
};
</script>
