<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item active">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Tables</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Files' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Suitcase" />
              <span class="nav-text">Files</span>
            </router-link>
            <div class="triangle"></div>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <!--=============== Left side End ================-->

    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="breadcrumb">
        <h1>Dashboard</h1>
      </div>
      <div class="separator-breadcrumb border-top"></div>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-profile-1 mb-4">
            <div class="card-body text-center">
              <label>Display Name</label>
              <input
                class="form-control form-control-rounded"
                type="text"
                v-model="displayName"
              />

              <br />
              <label>Email</label>
              <input
                class="form-control form-control-rounded"
                type="text"
                v-model="email"
              />

              <br />
              <label>Profile Picture</label>
              <input
                :multiple="false"
                @change="detectFiles($event)"
                accept="image/*"
                class="form-control"
                id="files"
                name="file"
                ref="uploadInput"
                type="file"
              />

              <div class="row" v-if="uploading && !uploadEnd">
                {{ progressUpload }}%
              </div>

              <br /><br />
              <button @click="update()" class="btn btn-primary btn-rounded">
                Save
              </button>

              <div class="card-socials-simple mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";
import { firestorage } from "../config/db";

export default {
  name: "UpdateProfile",

  data() {
    return {
      email: "",
      displayName: null,
      photoURL: null,
      fileName: "",

      progressUpload: 0,
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      downloadURL: "",
    };
  },

  created: function () {
    this.userData();
  },

  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },

    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x]);
      });
    },

    upload(file) {
      this.fileName = file.name;
      this.uploading = true;
      this.uploadTask = firestorage.ref("UserProfile/" + file.name).put(file);
    },

    userData: function () {
      var user = firebase.auth().currentUser;
      var name, email, photoUrl, uid;

      if (user != null) {
        name = user.displayName;
        email = user.email;
        photoUrl = user.photoURL;
        this.downloadURL = user.photoURL;
      }

      this.email = email;
      this.displayName = name;
      this.photoURL = photoUrl;
    },

    update: function () {
      var user = firebase.auth().currentUser;

      user.updateProfile({
        displayName: this.displayName,
        email: this.email,
        photoURL: this.downloadURL,
      });

      swal({
        type: "success",
        title: "Success!",
        text: "Your profile has been saved",
        showConfirmButton: false,
        timer: 1500,
      });

      //this.$router.replace('home')
      //this.$router.push({path: "/dashboard", name:"home", component: home,  props: true})
      this.$router.push({
        name: "Dashboard",
        params: {
          pEmail: this.email,
          pDisplayName: this.displayName,
          pPhotoURL: this.downloadURL,
        },
      });
    },
  },

  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.uploadEnd = true;
            this.downloadURL = downloadURL;
            this.$emit("downloadURL", downloadURL);
          });
        }
      );
    },
  },
};
</script>
