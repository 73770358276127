<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Ausloggen</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Produkte</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Sync' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Sync" />
              <span class="nav-text">Sync</span>
            </router-link>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Batches' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Batches</span>
            </router-link>
            <div class="triangle"></div>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>

    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="table-responsive">
        <table
          class="display table table-striped table-bordered"
          id="scroll_horizontal_table"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Datum</th>
              <th>Nummernkreis</th>
              <th>PaketNr</th>
              <th>Produkt pro Label</th>
              <th>Labels</th>
              <th>Produkte</th>
              <th>Länder</th>
            </tr>
          </thead>
          <tbody>
            <tr v-bind:key="batch" v-for="batch in orderBatches">
              <td>{{ batch.batch_name }}</td>
              <td>{{ batch.batch_date }}</td>
              <td>{{ batch.number_range }}</td>
              <td>{{ batch.package_number }}</td>
              <td>{{ batch.anzahl }}</td>
              <td>{{ batch.labels }}</td>
              <td>{{ batch.summe }}</td>
              <td>{{ batch.shipping_country }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";
import axios from "axios";
import { addTrackingInfoToFirebase } from "@/services/order.service";

export default {
  name: "CancellationRequests",

  data() {
    return {
      table: this.$router.currentRoute.params.id,
      apiBaseMapping: {
        "2021_1-audiobook-orders": "https://2020_1-audiobook.jp-superstore.de",
        "2020_3-carlender-orders": "https://carlender2020.jp-superstore.de",
        "2020_2-biografie-orders": "https://book2020.jp-superstore.de",
        "2021_2-carlender-orders": "https://carlender2022.jp-superstore.de",
        "2021_3-biografie-orders": "https://book2020.jp-superstore.de",
        "2022_1-boostwars-orders": "https://boostwars2022.jp-superstore.de",
        "2022_2-carlender-orders": "https://carlender2023.jp-superstore.de",
        "2023_4-carlender-orders": "https://carlender2024.jp-superstore.de",
      },
      apiBase: "",
      orderBatches: [],
      backendToken: "",
    };
  },

  created: function () {
    this.apiBase = this.apiBaseMapping["2023_4-carlender-orders"];
    this.getBackendAuthToken();
  },

  methods: {
    loadBatches: async function () {
      await axios
        .get(`${this.apiBase}/wp-json/batches/${this.backendToken}`)
        .then(async ({ data }) => {
          this.orderBatches = data;
        })
        .catch((err) => {
          logger(`Fehler beim Abruf der Batch-Daten: ${err}`);
        });
    },

    getBackendAuthToken: async function () {
      firebase
        .firestore()
        .collection("werkstatt-meta")
        .doc("auth")
        .get()
        .then((doc) => {
          this.backendToken = doc.get("backendToken");
          this.loadBatches();
        });
    },

    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },
  },
};
</script>
