<template>
  <div class="app-admin-wrap">
    <div class="main-header">
      <div class="logo">
        <img alt="" src="/assets/images/logo.png" />
      </div>

      <div class="d-flex align-items-center"></div>

      <div style="margin: auto"></div>

      <div class="header-part-right">
        <div class="dropdown">
          <div class="user colalign-self-end">
            <img
              alt=""
              aria-expanded="false"
              aria-haspopup="true"
              data-toggle="dropdown"
              id="userDropdown"
              src="/assets/images/logo.png"
              style="cursor: pointer"
            />
            <div
              aria-labelledby="userDropdown"
              class="dropdown-menu dropdown-menu-right"
            >
              <a @click="logout" class="dropdown-item" href="#">Sign out</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-content-wrap">
      <div
        class="sidebar-left open"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
      >
        <ul class="navigation-left">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Bar-Chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Search' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Search-on-Cloud"></i>
              <span class="nav-text">Suche</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Products' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Box-Full" />
              <span class="nav-text">Tables</span>
            </router-link>
            <div class="triangle"></div>
          </li>
          <li class="nav-item active">
            <router-link :to="{ name: 'Files' }" class="nav-item-hold">
              <i aria-hidden="true" class="nav-icon i-Suitcase" />
              <span class="nav-text">Files</span>
            </router-link>
            <div class="triangle"></div>
          </li>
        </ul>
      </div>
      <div class="sidebar-overlay"></div>
    </div>
    <div class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="breadcrumb">
        <h1>Files</h1>
      </div>

      <div class="separator-breadcrumb border-top"></div>

      <div class="row">
        <div class="col-md-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="card-title mb-3">File Upload</div>
              <form>
                <div class="row">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroupFileAddon01"
                        >Upload</span
                      >
                    </div>
                    <div class="custom-file">
                      <input
                        :multiple="false"
                        @change="detectFiles($event)"
                        accept="image/*"
                        class="custom-file-input"
                        for="inputGroupFileAddon01"
                        id="files"
                        name="file"
                        ref="uploadInput"
                        type="file"
                      />

                      <label class="custom-file-label" for="files"
                        >Choose file</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row" v-if="uploading && !uploadEnd">
                  {{ progressUpload }}%
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <div class="card text-left">
            <div class="card-body">
              <h4 class="card-title mb-3">File List</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table
                  class="display table table-striped table-bordered"
                  id="scroll_horizontal_table"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Preview/Download</th>
                      <th>Upload Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="file.key" v-for="file of allFiles">
                      <td>{{ file.imageName }}</td>
                      <td>
                        <a :href="file.downloadURL" target="__blank"
                          >Click To Open</a
                        >
                      </td>
                      <td>{{ file.date }}</td>
                      <td>
                        <span
                          @click="alertConfirm(file.imageName, file.key)"
                          class="text-danger mr-2"
                          style="cursor: pointer"
                        >
                          <i
                            aria-hidden="true"
                            class="nav-icon i-Close-Window font-weight-bold"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============ Body content End ============= -->
  </div>
  <!--=============== End app-admin-wrap ================-->
</template>

<script>
/* eslint-disable no-console */

import firebase from "firebase";
import { firestorage } from "../config/db";

export default {
  name: "files",

  data() {
    return {
      ref: firebase.firestore().collection("UserImages"),

      allFiles: [],
      photoURL: "",
      progressUpload: 0,
      fileName: "",
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      downloadURL: "",
      currentDate: "",
      imgFileName: "",
    };
  },

  created: function () {
    this.ref.onSnapshot((querySnapshot) => {
      this.allFiles = [];
      querySnapshot.forEach((doc) => {
        this.allFiles.push({
          key: doc.id,
          date: doc.data().date,
          downloadURL: doc.data().downloadURL,
          imageName: doc.data().imageName,
        });
      });
    });

    this.userData();
  },

  methods: {
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },

    userData: function () {
      var user = firebase.auth().currentUser;
      if (user != null) {
        this.photoURL = user.photoURL;
      }
    },

    selectFile() {
      this.$refs.uploadInput.click();
    },

    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x]);
      });
    },

    upload(file) {
      this.fileName = file.name;
      this.uploading = true;
      this.uploadTask = firestorage.ref("images/" + file.name).put(file);

      this.currentDate =
        new Date().getDay() +
        "/" +
        new Date().getMonth() +
        "/" +
        new Date().getFullYear();
      this.imgFileName = file.name;
    },

    alertConfirm(filename, key) {
      filename = filename;
      key = key;

      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#0CC27E",
        cancelButtonColor: "#FF586B",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        confirmButtonClass: "btn btn-success mr-5",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: !1,
      }).then(
        function () {
          firestorage
            .ref("images/" + filename)
            .delete()
            .then(() => {})
            .catch((error) => {
              console.error(`file delete error occured: ${error}`);
            });

          firebase.firestore().collection("UserImages").doc(key).delete();

          swal("Deleted!", "Your file has been deleted.", "success");
        },
        function (t) {
          "cancel" === t && swal("Cancelled", "Your file is safe :)", "error");
        }
      );

      this.uploading = false;
      this.uploadEnd = false;
      this.downloadURL = "";
    },
  },

  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        "state_changed",
        (sp) => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.uploadEnd = true;
            this.downloadURL = downloadURL;
            this.$emit("downloadURL", downloadURL);

            firebase.firestore().collection("UserImages").add({
              imageName: this.imgFileName,
              date: this.currentDate,
              downloadURL: downloadURL,
            });

            swal({
              type: "success",
              title: "Success!",
              text: "Your file has been saved",
              buttonsStyling: !1,
              confirmButtonClass: "btn btn-lg btn-success",
            });
          });
        }
      );
    },
  },
};
</script>
